import React, { useEffect, useState } from 'react'
import styles from './Steps.module.scss'
import { CheckMarkCircleIcon, ChevronArrowIcon } from 'components'
import clsx from 'clsx'
import { getCampaignTypeAsText, getCapitalizedText } from 'utils'
import { CAMPAIGN_STATUS, CAMPAIGN_STEP, PATH } from 'utils/constants'

export const DEFAULT_STEP_ITEMS = [
  {
    id: CAMPAIGN_STEP.DEFINE,
    order: 30,
    isOpened: false,
    text: 'Define Campaign & Rewards',
    route: `${PATH.CAMPAIGNS__EDIT}/define/`,
    subItems: [
      {
        id: CAMPAIGN_STEP.DEFINE__CAMPAIGN,
        order: 30.1,
        text: 'Campaign Name and Dates',
        route: `${PATH.CAMPAIGNS__EDIT}/define/`,
      },
      {
        id: CAMPAIGN_STEP.DEFINE__AMBASSADOR,
        order: 30.2,
        text: 'Ambassador Rewards',
        route: `${PATH.CAMPAIGNS__EDIT}/define/`,
      },
      {
        id: CAMPAIGN_STEP.DEFINE__PROSPECT,
        order: 30.3,
        text: 'Prospect Rewards',
        route: `${PATH.CAMPAIGNS__EDIT}/define/`,
      },
    ],
  },
  {
    id: CAMPAIGN_STEP.AMBASSADOR_SETUP,
    order: 40,
    isOpened: false,
    text: 'Ambassador Setup',
    route: `${PATH.CAMPAIGNS__EDIT}/ambassador/`,
    subItems: [
      {
        id: CAMPAIGN_STEP.AMBASSADOR_SETUP__INVITATIONS,
        order: 40.1,
        text: 'Invitations',
        route: `${PATH.CAMPAIGNS__EDIT}/ambassador/`,
      },
      {
        id: CAMPAIGN_STEP.AMBASSADOR_SETUP__DASHBOARD,
        order: 40.2,
        text: 'Dashboard',
        route: `${PATH.CAMPAIGNS__EDIT}/ambassador/`,
      },
      {
        id: CAMPAIGN_STEP.AMBASSADOR_SETUP__EMAILS,
        order: 40.3,
        text: 'Emails to Ambassador',
        route: `${PATH.CAMPAIGNS__EDIT}/ambassador/`,
      },
    ],
  },
  {
    id: CAMPAIGN_STEP.PROSPECT_SETUP,
    order: 50,
    isOpened: false,
    text: 'Prospect Setup',
    route: `${PATH.CAMPAIGNS__EDIT}/prospect/`,
    subItems: [
      {
        id: CAMPAIGN_STEP.PROSPECT_SETUP__INVITATIONS,
        order: 50.1,
        text: 'Invitation',
        route: `${PATH.CAMPAIGNS__EDIT}/prospect/`,
      },
      {
        id: CAMPAIGN_STEP.PROSPECT_SETUP__EMAILS,
        order: 50.2,
        text: 'Email to Prospect',
        route: `${PATH.CAMPAIGNS__EDIT}/prospect/`,
      },
    ],
  },
  {
    id: CAMPAIGN_STEP.LAUNCH,
    order: 60,
    isOpened: false,
    text: 'Launch Campaign',
    route: `${PATH.CAMPAIGNS__EDIT}/launch/`,
  },
]

const Steps = ({
  className = '',
  stepId: initStepId = CAMPAIGN_STEP.DEFINE,
  campaign = null,
  onChangeStep = () => null,
  activeCampaign,
  testCampaign,
  setSteps = (steps) => null,
}) => {
  const [initStep, setInitStep] = useState({ id: '' })
  const [step, setStep] = useState({ id: '' })
  const [topStepId, setTopStepId] = useState(CAMPAIGN_STEP.DEFINE)
  const [stepItems, setStepItems] = useState(DEFAULT_STEP_ITEMS)

  useEffect(() => {
    if (
      [CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.CLOSED].includes(
        campaign?.status
      )
    ) {
      let steps = DEFAULT_STEP_ITEMS.concat([
        {
          id: CAMPAIGN_STEP.VERIFICATION,
          order: 20,
          isOpened: false,
          text: 'Verification',
          route: `${PATH.CAMPAIGNS__EDIT}/verification/`,
        },
        {
          id: CAMPAIGN_STEP.PERFORMANCE,
          order: 10,
          isOpened: false,
          text: 'Performance',
          route: `${PATH.CAMPAIGNS__EDIT}/performance/`,
        },
      ])
      setStepItems(steps)
      setSteps(steps)
    } else {
      setStepItems(DEFAULT_STEP_ITEMS)
      setSteps(DEFAULT_STEP_ITEMS)
    }
  }, [campaign])

  const findStepItem = (items, idToFind) => {
    const parentStepId = idToFind.split('__')[0]

    try {
      items.forEach(({ id, order, text, subItems }) => {
        if (!id.startsWith(parentStepId)) return

        if (id === idToFind)
          throw new Error(JSON.stringify({ id, order, text }))
        if (subItems)
          throw new Error(JSON.stringify(findStepItem(subItems, idToFind)))
      })
    } catch ({ message }) {
      return JSON.parse(message)
    }
  }

  useEffect(() => {
    if (initStepId) {
      setInitStep(findStepItem(stepItems, initStepId))
      setTopStepId(initStepId.split('__')[0])
    }

    if (step?.id !== initStepId) {
      setStep(findStepItem(stepItems, initStepId))
    }
    // eslint-disable-next-line
  }, [initStepId, stepItems])

  useEffect(() => {
    setStepItems((stepItems) =>
      stepItems.map((item) => ({
        ...item,
        isOpened: item.id === topStepId,
      }))
    )
  }, [topStepId])

  const handleClickStep = (step) => {
    const stepChunks = step.id.split('__')

    if (stepChunks.length === 1)
      setStepItems((stepItems) => {
        return stepItems.map((item) => {
          return {
            ...item,
            isOpened:
              item.id === stepChunks[0] ? !item.isOpened : item.isOpened,
          }
        })
      })

    setStep(step)
    onChangeStep(step)
  }

  if (!step?.id || !initStep?.order) return <React.Fragment />

  return (
    <div className={className}>
      <div className={styles.header}>
        <p
          dangerouslySetInnerHTML={{
            __html: `${
              activeCampaign
                ? 'You are editing an <span style="color:red">ACTIVE</span> Campaign'
                : testCampaign
                ? 'You are editing a <span style="color:red">TEST</span> Campaign'
                : getCapitalizedText(campaign.status) + ' Campaigns'
            }`,
          }}
        />

        <div className={styles.title__wrapper}>
          <h6
            className={clsx(styles.title, styles[`title--${campaign.status}`])}
            dangerouslySetInnerHTML={{ __html: campaign.name }}
          />
        </div>

        <p
          className={styles.type}
          dangerouslySetInnerHTML={{
            __html: `${getCampaignTypeAsText(campaign.type)} campaign`,
          }}
        />
      </div>

      <div className={styles.main}>
        {stepItems.map(
          ({ id, isOpened, text, order, subItems, route }, sIdx) => (
            <div className={styles.step__wrapper} key={order}>
              <div
                className={clsx(
                  styles.step,
                  isOpened && styles['step--active']
                )}
                onClick={() => handleClickStep({ id, text, order, route })}
                step-id={sIdx + 1}
              >
                <CheckMarkCircleIcon
                  className={clsx(
                    styles.icon__circle_check_mark,
                    (order >= Math.floor(initStep.order) ||
                      order < DEFAULT_STEP_ITEMS[0].order) &&
                      styles[`icon__circle_check_mark--hidden`]
                  )}
                />

                <span className={styles.step__text}>{text}</span>

                {subItems ? (
                  <ChevronArrowIcon
                    className={clsx(
                      styles.icon__chevron,
                      isOpened && styles['icon__chevron--opened']
                    )}
                  />
                ) : null}
              </div>

              {subItems ? (
                <div
                  className={clsx(
                    styles.sub_steps__wrapper,
                    isOpened && styles['sub_steps__wrapper--opened']
                  )}
                  style={{ height: isOpened && 40 * subItems.length + 16 }}
                >
                  {subItems.map(({ id, text, order }) => (
                    <div
                      className={clsx(
                        styles.sub_step,
                        step.id === id && styles['sub_step--active']
                      )}
                      key={order}
                      onClick={() => handleClickStep({ id, text, order })}
                    >
                      <span>{text}</span>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default Steps
