import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './TextArea.module.scss'
import { HelpModal } from 'components'

const TextArea = ({
  classNames = { container: '', label: '', textarea: '' },
  tabIndex = '',
  label = null,
  placeholder = '',
  value: initValue = '',
  captionText = null,
  requiredShown = false,
  optionalShown = false,
  isError = false,
  readOnly = false,
  disabled = false,
  onChange: handleChangeValue = () => null,
  rows = 3,
  help = false,
  helpChildren = <></>,
  handleFocus: onFocusChanged = () => null,
}) => {
  const [value, setValue] = useState('')

  useEffect(() => setValue(initValue || ''), [initValue])

  const handleChange = (e) => {
    setValue(e.target.value)
    handleChangeValue(e.target.value)
  }

  const onFocus = (e) => {
    onFocusChanged(e.target)
  }

  return (
    <div className={clsx(classNames.container, styles.text_textarea__wrapper)}>
      {label ? (
        <div className={styles.text_textarea__dis}>
          <label
            className={clsx(classNames.label, styles.text_textarea__label)}
          >
            {label}
            {requiredShown ? (
              <span className={styles.text_textarea__required}>&nbsp;*</span>
            ) : null}
            {optionalShown ? (
              <span className={styles.text_textarea__optional}>
                &nbsp;(optional)
              </span>
            ) : null}
          </label>
          {help && <HelpModal help={help}>{helpChildren}</HelpModal>}
        </div>
      ) : null}

      <textarea
        className={clsx(
          classNames.textarea,
          styles.text_textarea,
          isError && styles[`text_textarea--error`]
        )}
        tabIndex={tabIndex}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        rows={rows}
        readOnly={readOnly}
        disabled={disabled}
        onFocus={onFocus}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>
    </div>
  )
}

export default TextArea
